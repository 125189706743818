import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const About = () => {
  return (
    <Layout>
      <main className="page">
        <section className="about-page">
          <article>
            <h3>About Beard Law Company</h3>
            <p>
              At Beard Law Company, we provide proactive and responsive legal
              advice across a broad spectrum of individuals, organizations, and
              enterprises. We strive to actively address your legal requirements
              with a customer-oriented focus. As a small law firm, we are able
              to provide cost-effective legal solutions and have a reputation to
              scale our capabilities to handle large and complex legal matters.
              Beard Law Company gives clients a hands-on experience that is rare
              in larger legal service providers. Let us help you in your legal
              endeavors. <Link to="/contact">Contact</Link> us today!
            </p>
            <div className="about-contact">
              <Link to="/contact" className="btn">
                Contact
              </Link>
            </div>
          </article>
          <StaticImage
            src="../assets/images/bookshelf-1.jpeg"
            alt="bookshelf"
            className="about-img"
            placeholder="blurred"
          />
        </section>
      </main>
    </Layout>
  )
}

export default About
